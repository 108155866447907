<template>
    <KCourse loader-class="MBcont" course-id="2" title="Анатомия тела" :items="items">
        <div class="MBtextcont">
            <p>Наконец, мы подошли к работе с пластиком! Хочу сказать, что очень важно научиться чувствовать материал, с которым вы работаете. Изучите его, поймите пользу и риски каждого его состояния. Сырой, мягкий, очень мягкий, почти жидкий, полусухой, полностью сухой. Поверьте, каждое состояние можно использовать с большой пользой для своей работы!</p>
            <p class='nomargin'><b>На этом занятии нам понадобятся:</b></p>
            <ol>
                <li>Трафареты</li>
                <li>Пластик La Doll</li>
                <li>Нож или стек лопаткой</li>
                <li>Пластилиновые заготовки</li>
                <li>Коктейльные трубочки диамтером 4-5мм</li>
                <li>Пищевая пленка</li>
                <li>Вода</li>
            </ol>
        </div>

        <VideoView video-id="12a245072a1b47caadf19d5043809c6a"/>

        <div class="MBtextcont">
            <p><b>!!!</b> Очень важно уже завтра, примерно через 10-12 часов пока пластик будет в полусыром состоянии, вскрыть формы и достать пластилиновые заготовки. Т.е. верхний слой уже будет сухим и надежно держать форму, а внутренний еще влажный, поэтому его проще будет разрезать по бокам. С полностью сухими заготовками придется очень долго мучиться разрезать их сложно, особенно тело </p>

            <p class='nomargin'><b>Задание:</b></p>
            <p>Обтянуть тело, ноги, руки, голову пластиком близко по трафаретам.</p>

        </div>
    </KCourse>
</template>

<script>
    import VideoView from "../../Widgets/VideoView";
    import KCourse from "../../Widgets/KCourse";

    export default {
        name: "MBCourseHead",
        components: {
            VideoView,
            KCourse,
        },
        data() {
        return {
            items: [
                { title: "Готовим трафареты", url:"/mycourses/body/1"},
                { title: "Заготовки из пластилина", url:"/mycourses/body/2"},
                { title: "Подготовка к обтяжке", url:"/mycourses/body/3"},
                { title: "Обтяжка пластиком", url:"/mycourses/body/4"},
                { title: "Достаем пластилин", url:"/mycourses/body/5"},
                { title: "Основа кисти", url:"/mycourses/body/6"},
                { title: "Основа стопы", url:"/mycourses/body/7"},
                { title: "Анатомия руки", url:"/mycourses/body/8"},
                { title: "Анатомия кисти", url:"/mycourses/body/9"},
                { title: "Анатомия ноги", url:"/mycourses/body/10"},
                { title: "Анатомия стоп", url:"/mycourses/body/11"},
                { title: "Анатомия тела", url:"/mycourses/body/12"},
            ],
        }
        }
    }
</script>